import React, { useState, useEffect, useRef } from "react";
import { connect, useDispatch } from "react-redux";
import { ARTWORK } from "../../../../types";
import { generateLayout } from "crossword-layout-generator";
import {
  AWS_API,
  URL_API_CROSSWORD_PUZZLE,
  DEFAULT_FONT,
  ASSET_URL,
  CLOUDFRONT_URL,
} from "../../../../config";
import {
  Button,
  Form,
  Input,
  Modal,
  InputNumber,
  Card,
  Switch,
  Space,
  Skeleton,
  message,
  Checkbox,
  Row,
  Col,
  Popconfirm,
} from "antd";
import ColorPicker from "./Scrabble/ColorPicker";
import FontSelector from "../../../Font/FontSelector";
import TagsForm from "./Scrabble/TagsForm";
import axios from "axios";

import SelectBackgroundScrabble from "./Scrabble/SelectBackgroundScrabble";
import AddBackgroundSrabble from "./Scrabble/AddBackgroundSrabble";
import { get, pick } from "lodash";
import gql from "../../../../api/gql";
import Konva from "konva";
import DirectionalPadV2 from "./Scrabble/DirectionalPadV2";
import { AiFillSetting } from "react-icons/ai";
const ScrabbleSettings = ({ layer }) => {
  const canvasRef = useRef(null);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [visibleSetting, setVisibleSetting] = useState(false);
  const [bgsScrabble, setBgsScrabble] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingApply, setLoadingApply] = useState(false);
  const [imageURL, setImageURL] = useState("");
  const [fontFamilys, setFontFamilys] = useState({});

  const [customMarginLeftText, setCustomMarginLeftText] = useState(0);
  const [customMarginTopText, setCustomMarginTopText] = useState(0);

  const [customMarginLeftPointText, setCustomMarginLeftPointText] = useState(0);
  const [customMarginTopPointText, setCustomMarginTopPointText] = useState(0);

  const showModel = () => {
    setVisibleSetting(true);
  };

  const upload = async (base64) => {
    var file = await fetch(base64)
      .then((res) => res.arrayBuffer())
      .then((buf) => new File([buf], "tmp.png", { type: "image/png" }));
    var signedUrl = await getSignedUrl(file.type);
    await axios
      .put(signedUrl.url, file, {
        headers: {
          "Content-Type": file.type,
        },
      })
      .catch((err) => console.error(err));
    return { key: signedUrl.key };
  };

  const getSignedUrl = async (mime) => {
    var headers = new Headers();
    headers.append("Content-Type", "application/json");
    var requestOptions = {
      method: "GET",
      headers: headers,
      redirect: "follow",
    };
    var signedUrl = null;
    await fetch(`${AWS_API}/signedUrl?type=${mime}`, requestOptions)
      .then((response) => response.json())
      .then((res) => (signedUrl = res))
      .catch((err) => console.error(err));
    return signedUrl;
  };

  const removeVietnameseTones = (str) => {
    str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a");
    str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e");
    str = str.replace(/ì|í|ị|ỉ|ĩ/g, "i");
    str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o");
    str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u");
    str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y");
    str = str.replace(/đ/g, "d");

    str = str.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, "A");
    str = str.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, "E");
    str = str.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, "I");
    str = str.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, "O");
    str = str.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, "U");
    str = str.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, "Y");
    str = str.replace(/Đ/g, "D");

    // Some additional characters
    str = str.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, ""); // Combining accents
    str = str.replace(/\u02C6|\u0306|\u031B/g, ""); // Other diacritics

    return str;
  };

  const getCharGrid = (tags) => {
    const words = tags.map((e) => {
      return {
        clue: "",
        answer: removeVietnameseTones(e).toUpperCase(),
      };
    });
    const crossword = generateLayout(words);

    let charGrid = crossword.table;
    charGrid = charGrid.map((innerArray) =>
      innerArray.map((item) => (item === "-" ? "" : item))
    );
    return charGrid;
  };

  const loadImage = (url) => {
    if(url.indexOf('remove-bg') >= 0){
      const splitUrl = url.split('remove-bg');
      const key = splitUrl[1];
      url = CLOUDFRONT_URL + 'auto' + key;
    }    
    return new Promise((resolve, reject) => {
      var imageObj = new Image();
      imageObj.crossOrigin = "anonymous";
      imageObj.onload = function () {
        resolve(imageObj); // Trả về đối tượng hình ảnh khi đã tải xong
      };
      imageObj.onerror = function () {
        reject(new Error("Failed to load image at " + url)); // Trả về lỗi nếu việc tải hình ảnh thất bại
      };
      imageObj.src = url;
    });
  };

  const configGroup = ({ columnIndex, rowIndex, tileSize, padding, scale }) => {
    const configs = {
      x: columnIndex * (tileSize + padding) + (padding + padding / 2),
      y: rowIndex * (tileSize + padding) + (padding + padding / 2),
    };
    ["x", "y"].forEach((t) => {
      if (configs[t]) {
        configs[t] = configs[t] * scale;
      }
    });
    return configs;
  };

  const configRect = ({
    tileSize,
    background,
    shadowColor = "rgba(0, 0, 0, 0.5)",
    shadowBlur = 2,
    shadowOffsetX = 0,
    shadowOffsetY = 0,
    shadowOpacity = 1,
    borderColor = "#ac5d1d",
    borderWidth = 2,
    borderRadius = 5,
    scale,
  }) => {
    const configs = {
      width: tileSize,
      height: tileSize,
      fillPatternImage: background,
      fillPatternScale: {
        x: (tileSize / background.width) * scale,
        y: (tileSize / background.height) * scale,
      },
      shadowColor,
      shadowBlur,
      shadowOffsetX,
      shadowOffsetY,
      shadowOpacity,
      stroke: borderColor,
      strokeWidth: borderWidth,
      cornerRadius: borderRadius,
    };

    [
      "width",
      "height",
      "shadowBlur",
      "shadowOffsetX",
      "shadowOffsetY",
      "strokeWidth",
      "cornerRadius",
    ].forEach((t) => {
      if (configs[t]) {
        configs[t] = configs[t] * scale;
      }
    });
    return configs;
  };

  const getLetterPoints = (letter) => {
    const letterPoints = {
      A: 1,
      B: 3,
      C: 3,
      D: 2,
      E: 1,
      F: 4,
      G: 2,
      H: 4,
      I: 1,
      J: 8,
      K: 5,
      L: 1,
      M: 3,
      N: 1,
      O: 1,
      P: 3,
      Q: 10,
      R: 1,
      S: 1,
      T: 1,
      U: 1,
      V: 4,
      W: 4,
      X: 8,
      Y: 4,
      Z: 10,
    };
    const upperLetter = letter.toUpperCase();
    return letterPoints[upperLetter] || 0;
  };

  const configsPointsText = ({
    char,
    letterPointsFontSize = 15,
    letterPointsFontFamily,
    textColor = "#000000",
    tileSize,
    scale,
    customMarginLeftPointText,
    customMarginTopPointText,
    ratio
  }) => {
    const points = getLetterPoints(char);
    const configs = {
      text: points.toString(),
      fontSize: letterPointsFontSize,
      fontFamily: letterPointsFontFamily,
      fill: textColor,
      x: -(10 * ratio) + customMarginLeftPointText,
      y: -(10 * ratio) + customMarginTopPointText,
      width: tileSize,
      height: tileSize,
      verticalAlign: "bottom",
      align: "right",
    };
    ["fontSize", "x", "y", "width", "height"].forEach((t) => {
      if (configs[t]) {
        configs[t] = configs[t] * scale;
      }
    });
    return configs;
  };

  const configsText = ({
    char,
    fontSize = 60,
    textColor = "#000000",
    tileSize,
    fontFamily,
    textOpacity = 1,
    scale,
    customMarginLeftText,
    customMarginTopText,
  }) => {
    const configs = {
      text: char,
      fontSize,
      fontStyle: "bold",
      fill: textColor,
      width: tileSize,
      height: tileSize,
      align: "center",
      x: 0 + customMarginLeftText,
      y: 0 + customMarginTopText,
      verticalAlign: "middle",
      fontFamily: fontFamily,
      opacity: textOpacity,
      lineHeight: 0,
    };
    ["fontSize", "width", "height"].forEach((t) => {
      if (configs[t]) {
        configs[t] = configs[t] * scale;
      }
    });
    return configs;
  };

  const containsDigit = (array) => {
    const digitRegex = /[0-9]/; // Biểu thức chính quy để kiểm tra chữ số từ 0 đến 9
    return array.some((str) => digitRegex.test(str));
  }

  const containsSpace = (array) => {
    const spaceRegex = /\s/; // Biểu thức chính quy để kiểm tra dấu cách trống
    return array.some((str) => spaceRegex.test(str));
  }

  const handleRender = async () => {
    const { width = 500, height = 500 } = layer;
    
    const values = form.getFieldsValue();
    const ratio = values.values || 4;
    values.fontFamily = values.fontFamily || DEFAULT_FONT;
    values.width = width;
    values.height = height;

    if(values.tags.length <= 1){
      message.error("Please enter at least 2 tags");
      return;
    }
    if(containsDigit(values.tags)){
      message.error("Tags cannot contain numbers.");
      return;
    }
    if(containsSpace(values.tags)){
      message.error("Tags cannot contain spaces.");
      return;
    }
    setLoading(true);
    [
      'margin',
      'borderRadius',
      'borderWidth',
      'fontSize',
      'height',
      'letterPointsFontSize',
      'shadowBlur',
      'shadowOffsetX',
      'shadowOffsetY',
      'customMarginLeftPointText',
      'customMarginLeftText',
      'customMarginTopPointText',
      'customMarginTopText',
      'shadowOpacity'
    ].forEach(key => {
      if(values[key]){
        values[key] = values[key] * ratio;
      }
    });

    const {
      tags,
      backgroundImage,
      backgroundScrabbleImages,
      borderColor,
      borderRadius,
      borderWidth,
      fontFamily,
      fontSize,
      letterPointsEnabled,
      letterPointsFontFamily,
      letterPointsFontSize,
      margin,
      maxLength,
      shadowBlur,
      shadowColor,
      shadowOffsetX,
      shadowOffsetY,
      shadowOpacity,
      textColor,
      textOpacity,
      titleBackground,
      customMarginLeftText = 0,
      customMarginTopText = 0,
      customMarginLeftPointText = 0,
      customMarginTopPointText = 0,
    } = values;

    const tileSize = 100 * ratio;
    const padding = margin;
    let charGrid = getCharGrid(tags);

    const rows = charGrid.length;
    const columns = charGrid[0].length;

    const contentWidth = columns * (tileSize + padding) + padding * 2;
    const contentHeight = rows * (tileSize + padding) + padding * 2;

    const iWidth = 1000 * ratio;
    const iScale = iWidth / width;

    const widthCanvas = width * iScale;
    const heightCanvas = height * iScale;

    const scaleX = widthCanvas / contentWidth;
    const scaleY = heightCanvas / contentHeight;
    const scale = Math.min(scaleX, scaleY);

    const offsetX = (widthCanvas - contentWidth * scale) / 2;
    const offsetY = (heightCanvas - contentHeight * scale) / 2;

    const stage = new Konva.Stage({
      container: canvasRef.current,
      width: widthCanvas,
      height: heightCanvas,
    });

    const layerKonva = new Konva.Layer({
      x: offsetX,
      y: offsetY,
      width: contentWidth * scale,
      height: contentHeight * scale,
    });
    stage.add(layerKonva);

    let background = await loadImage(
      backgroundImage
        ? backgroundImage
        : "https://d111oohu250q1p.cloudfront.net/upload-custom/1uJC3iehJ9HA6pfCKjWm67.png"
    );

    charGrid.forEach((row, rowIndex) => {
      row.forEach((char, columnIndex) => {
        if (char) {
          // Create a group for each tile to combine background and text
          const group = new Konva.Group(
            configGroup({
              columnIndex,
              rowIndex,
              padding,
              scale,
              tileSize,
            })
          );

          // Add tile background
          const tile = new Konva.Rect(
            configRect({
              tileSize,
              background,
              shadowColor,
              shadowBlur,
              shadowOffsetX,
              shadowOffsetY,
              shadowOpacity,
              borderColor,
              borderWidth,
              borderRadius,
              scale,
            })
          );
          group.add(tile);

          // Add text to tile
          const text = new Konva.Text(
            configsText({
              char,
              fontFamily,
              fontSize,
              scale,
              textColor,
              textOpacity,
              tileSize,
              customMarginLeftText,
              customMarginTopText,
            })
          );
          group.add(text);

          if (letterPointsEnabled) {
            const pointsText = new Konva.Text(
              configsPointsText({
                char,
                letterPointsFontFamily,
                letterPointsFontSize,
                scale,
                textColor,
                tileSize,
                customMarginLeftPointText,
                customMarginTopPointText,
                ratio
              })
            );
            group.add(pointsText);
          }
          layerKonva.add(group);
        }
      });
    });

    layerKonva.draw();

    const canvas = stage.toCanvas();
    const base64 = canvas.toDataURL();
    if (base64) {
      setImageURL(base64);
    }
    setLoading(false);
  };

  const loadFont = async (fontName) => {
    if (!fontName || fontName == "Arial") return;
    const [fontId, variant] = fontName.split("-");
    const fontQuery = `
      query($id: String!) {
        font(id: $id) {
          id
          family
          variants {
            variant
            file {
              url
            }
          }
        }
      }
    `;
    const query = await gql
      .request(fontQuery, {
        id: fontId,
      })
      .then((res) => {
        return res.font;
      });
    if (query && Array.isArray(query.variants)) {
      const WebFontLoader = require("webfontloader");
      // const variants = query.variants || [];
      query.variants
        .filter((v) => v.variant === variant)
        .forEach((v) => {
          const css = `@font-face {font-family:"${fontName}";src:url("${v.file.url}") format("truetype");}`;
          WebFontLoader.load({
            custom: {
              families: [fontName],
              urls: [`data:text/css;base64,${btoa(css)}`],
            },
            fontactive: () => {
              // callback();
              // message.success('ok')
            },
            fontinactive: (familyName) => {
              message.error(`Could not load ${familyName} font`);
            },
            timeout: 20000,
          });
        });
    }
  };

  const handleApply = async () => {
    if (imageURL.indexOf("base64") >= 0) {
      setLoadingApply(true);
      const upfile = await upload(imageURL);
      const configs = form.getFieldsValue();
      const valuesLayer = get(layer, "values", []);
      valuesLayer[0].file = pick(upfile, ["id", "key"]);
    
      dispatch({
        type: ARTWORK.SET_LAYER,
        payload: {
          ...layer,
          values: valuesLayer,
          configsScrabble: configs,
          imageScrableRender: ASSET_URL + upfile.key
        },
      });
      // await OnClickSaveSetting();
      setLoadingApply(false);
      setVisibleSetting(false);
    }
  };
  const OnClickSaveSetting = () => {
    const values = form.getFieldsValue();
    dispatch({
      type: ARTWORK.SET_LAYER,
      payload: {
        ...layer,
        configsScrabble: values,
      },
    });
    setVisibleSetting(false);
  };
  useEffect(() => {
    const values = { ...layer.configsScrabble };
    form.setFieldsValue({ ...values });
    setBgsScrabble(values.backgroundScrabbleImages || []);
    setCustomMarginLeftText(get(values, "customMarginLeftText", 0));
    setCustomMarginTopText(get(values, "customMarginTopText", 0));

    setCustomMarginLeftPointText(get(values, "customMarginLeftPointText", 0));
    setCustomMarginTopPointText(get(values, "customMarginTopPointText", 0));

    const fontFamily = get(values, "fontFamily", null);
    const letterPointsFontFamily = get(values, "letterPointsFontFamily", null);

    if (fontFamily && fontFamily != "") {
      loadFont(fontFamily);
    }

    if (letterPointsFontFamily && letterPointsFontFamily != "") {
      loadFont(letterPointsFontFamily);
    }
    if(get(layer, "imageScrableRender", null)){
      setImageURL(get(layer, "imageScrableRender", null))
    }
    setFontFamilys({
      fontFamily,
      letterPointsFontFamily
    });
  }, []);

  useEffect(() => {
    const values = form.getFieldsValue();
    values.customMarginTopText = customMarginTopText || 0;
    values.customMarginLeftText = customMarginLeftText || 0;
    values.customMarginLeftPointText = customMarginLeftPointText || 0;
    values.customMarginTopPointText = customMarginTopPointText || 0;
    form.setFieldsValue({ ...values });
  }, [
    customMarginTopText,
    customMarginLeftText,
    customMarginLeftPointText,
    customMarginTopPointText,
  ]);

  const handleChangeBackgroundScrabbleImages = (v) => {
    const values = form.getFieldsValue();
    values.backgroundScrabbleImages = v;
    if (!v.includes(values.backgroundImage)) {
      values.backgroundImage = "";
    }
    if (v.length > 0 && !values.backgroundImage) {
      values.backgroundImage = v[0];
    }
    form.setFieldsValue({ ...values });
    setBgsScrabble(v);
  };

  const handleChangeDirectiona = (value) => {
    switch (value.key) {
      case "top":
        setCustomMarginTopText(value.value);
        break;
      case "left":
        setCustomMarginLeftText(value.value);
        break;
      default:
        break;
    }
  };

  const handleChangeDirectionaPoint = (value) => {
    switch (value.key) {
      case "top":
        setCustomMarginTopPointText(value.value);
        break;
      case "left":
        setCustomMarginLeftPointText(value.value);
        break;
      default:
        break;
    }
  };

  const checkImageBase64 = (url) => {
    if (url.indexOf("base64") >= 0) {
      return true;
    }
    return false;
  };

  const handleDeleteFont = (name) => {
    const values = form.getFieldsValue();
    values[name] = "";
    form.setFieldsValue({
      ...values,
    });
    const fonts = fontFamilys;
    fonts[name] = "";
    setFontFamilys({ ...fonts });
  };

  const handleFontChange = async (v, key) => {
    const values = form.getFieldsValue();
    values[key] = v;
    await loadFont(v);
    form.setFieldsValue({
      ...values,
    });
    const fonts = fontFamilys;
    fonts[key] = v;
    setFontFamilys({...fonts});
  };
  const handleChangeTags = (v) => {
    const values = form.getFieldsValue();
    values.tags = v;
    form.setFieldsValue({
      ...values,
    });
  };
  return (
    <>
      <div>
        <div
          style={{
            position: "fixed",
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            width: 1000,
            height: 1000,
            background: "#fff",
            display: "none",
          }}
        >
          <canvas
            ref={canvasRef}
            width={1000}
            height={1000}
            style={{ border: "1px solid black" }}
          ></canvas>
        </div>
        <Form.Item
          name={["personalized", "required"]}
          valuePropName="checked"
          label="Is required"
          labelCol={{ span: 21 }}
          wrapperCol={{ span: 3 }}
          labelAlign="left"
          style={{ textAlign: "right" }}
        >
          <Checkbox />
        </Form.Item>
        <Modal
          title="Settings"
          centered
          visible={visibleSetting}
          onOk={OnClickSaveSetting}
          onCancel={() => setVisibleSetting(false)}
          footer={[
            <Space key="10">
              <Button
                key={1}
                onClick={() => setVisibleSetting(false)}
                style={{
                  borderRadius: 3,
                }}
              >
                Cancel
              </Button>
              <Button
                key={3}
                type="primary"
                onClick={OnClickSaveSetting}
                style={{
                  borderRadius: 3,
                }}
              >
                Save settings
              </Button>
            </Space>,
          ]}
          width={1111}
          okText={"Save settings"}
          className={"modal_setting modal_setting_crossword"}
        >
          <div className={"body_modal_setting"}>
            <div
              style={{
                height: "100%",
                overflow: "auto",
              }}
              className={"body_modal_setting_item1"}
            >
              <div
                style={{
                  width: "100%",
                  background: "#f2f2f2",
                  padding: "10px",
                  position: "sticky",
                  top: 0,
                  zIndex: 999999,
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <Space>
                  <Button
                    loading={loading}
                    onClick={handleRender}
                    style={{
                      borderRadius: 3,
                    }}
                  >
                    Render - Check Setting
                  </Button>
                  {imageURL && checkImageBase64(imageURL) && (
                    <Button 
                      type="primary" 
                      loading={loadingApply} 
                      onClick={handleApply}
                      style={{
                        borderRadius: 3
                      }}
                    >
                      Apply and Save settings
                    </Button>
                  )}
                </Space>
              </div>
              <Form layout="vertical" form={form}>
                <Card
                  className="card-settingt-srabble"
                  title=""
                  bordered={false}
                  style={{ width: "100%" }}
                >
                  <Form.Item label="Tags" name={["tags"]}>
                    <TagsForm onChange={handleChangeTags} />
                  </Form.Item>
                  <h4>Background Image</h4>
                  <div
                    style={{
                      display: "flex",
                    }}
                  >
                    <Form.Item name={["backgroundScrabbleImages"]}>
                      <AddBackgroundSrabble
                        onChange={handleChangeBackgroundScrabbleImages}
                      />
                    </Form.Item>
                    <Form.Item name={["backgroundImage"]}>
                      <SelectBackgroundScrabble
                        bgsScrabble={bgsScrabble}
                        onChangeBgsScrabble={
                          handleChangeBackgroundScrabbleImages
                        }
                      />
                    </Form.Item>
                  </div>
                  <Form.Item
                    label="Background Color Label"
                    name={["titleBackground"]}
                  >
                    <Input />
                  </Form.Item>
                  <Row>
                    <Col
                      span={8}
                      style={{
                        marginRight: 5,
                      }}
                    >
                      <Form.Item label="Max length" name={["maxLength"]}>
                        <InputNumber
                          style={{
                            width: "100%",
                          }}
                          min={0}
                          step={1}
                        />
                      </Form.Item>
                    </Col>
                    <Col
                      span={8}
                      style={{
                        marginRight: 5,
                      }}
                    >
                      <Form.Item label="Margin" name={["margin"]}>
                        <InputNumber
                          style={{
                            width: "100%",
                          }}
                          min={0}
                          step={1}
                        />
                      </Form.Item>
                    </Col>
                    <Col
                      span={8}
                      style={{
                        marginRight: 5,
                      }}
                    >
                      <Form.Item label="Scale" name={["scale"]}>
                        <InputNumber
                          defaultValue={4}
                          style={{
                            width: "100%",
                          }}
                          min={0}
                          step={1}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Card>

                <Card
                  className="card-settingt-srabble"
                  title={<strong>Border</strong>}
                  bordered={false}
                  style={{ width: "100%" }}
                >
                  <Row>
                    <Col span={8}>
                      <Form.Item label="Border Color" name={["borderColor"]}>
                        <ColorPicker
                          style={{
                            width: "100%",
                          }}
                        />
                      </Form.Item>
                    </Col>

                    <Col
                      span={8}
                      style={{
                        paddingRight: 5,
                      }}
                    >
                      <Form.Item label="Border width" name={["borderWidth"]}>
                        <InputNumber
                          style={{
                            width: "100%",
                          }}
                          min={0}
                          step={1}
                        />
                      </Form.Item>
                    </Col>

                    <Col span={8}>
                      <Form.Item label="Border radius" name={["borderRadius"]}>
                        <InputNumber
                          style={{
                            width: "100%",
                          }}
                          min={0}
                          step={1}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Card>

                <Card
                  className="card-settingt-srabble"
                  title={<strong>Shadow</strong>}
                  bordered={false}
                  style={{ width: "100%" }}
                >
                  <Row>
                    <Col
                      span={8}
                      style={{
                        paddingRight: 5,
                      }}
                    >
                      <Form.Item label="Shadow color" name={["shadowColor"]}>
                        <ColorPicker />
                      </Form.Item>
                    </Col>
                    <Col
                      span={8}
                      style={{
                        paddingRight: 5,
                      }}
                    >
                      <Form.Item label="shadowBlur" name={["shadowBlur"]}>
                        <InputNumber
                          style={{
                            width: "100%",
                          }}
                          min={0}
                          step={1}
                        />
                      </Form.Item>
                    </Col>
                    <Col
                      span={8}
                      style={{
                        paddingRight: 5,
                      }}
                    >
                      <Form.Item
                        label="Shadow offset X"
                        name={["shadowOffsetX"]}
                      >
                        <InputNumber
                          style={{
                            width: "100%",
                          }}
                          min={-255}
                          max={255}
                          step={1}
                        />
                      </Form.Item>
                    </Col>
                    <Col
                      span={8}
                      style={{
                        paddingRight: 5,
                      }}
                    >
                      <Form.Item
                        label="Shadow offset Y"
                        name={["shadowOffsetY"]}
                      >
                        <InputNumber
                          style={{
                            width: "100%",
                          }}
                          min={-255}
                          max={255}
                          step={1}
                        />
                      </Form.Item>
                    </Col>
                    <Col
                      span={8}
                      style={{
                        paddingRight: 5,
                      }}
                    >
                      <Form.Item
                        label="Shadow opacity"
                        name={["shadowOpacity"]}
                      >
                        <InputNumber
                          style={{
                            width: "100%",
                          }}
                          min={0}
                          max={1}
                          step={0.1}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Card>

                <Card
                  className="card-settingt-srabble"
                  title={<strong>Text</strong>}
                  bordered={false}
                  style={{ width: "100%" }}
                >
                  <Row>
                    <Col
                      style={{
                        paddingRight: 5,
                      }}
                      span={8}
                    >
                      <Form.Item label="Text color" name={["textColor"]}>
                        <ColorPicker />
                      </Form.Item>
                    </Col>

                    <Col
                      style={{
                        paddingRight: 5,
                      }}
                      span={8}
                    >
                      <Form.Item label="Text opacity" name={["textOpacity"]}>
                        <InputNumber
                          style={{
                            width: "100%",
                          }}
                          min={0}
                          max={1}
                          step={0.1}
                        />
                      </Form.Item>
                    </Col>
                    <Col
                      style={{
                        paddingRight: 5,
                      }}
                      span={8}
                    >
                      <Form.Item label="Font size" name={["fontSize"]}>
                        <InputNumber
                          style={{
                            width: "100%",
                          }}
                          min={0}
                          step={1}
                        />
                      </Form.Item>
                    </Col>

                    <Col
                      style={{
                        paddingRight: 5,
                      }}
                      span={8}
                    >
                      <Form.Item
                        className="scrabble_font"
                        label={
                          <div
                            style={{
                              display: "flex",
                            }}
                          >
                            Font Family:{" "}
                            {fontFamilys.fontFamily &&
                              fontFamilys.fontFamily != "Arial" && (
                                <Popconfirm
                                  placement="topLeft"
                                  title="Are you sure you want to delete?"
                                  onConfirm={() =>
                                    handleDeleteFont("fontFamily")
                                  }
                                  okText="Yes"
                                  cancelText="No"
                                >
                                  <Button
                                    style={{
                                      width: "fit-content",
                                      padding: 0,
                                      height: "fit-content",
                                      color: "red",
                                      marginLeft: 5,
                                    }}
                                    type="link"
                                  >
                                    Delete
                                  </Button>
                                </Popconfirm>
                              )}
                          </div>
                        }
                        valuePropName="fontFamily"
                        name={["fontFamily"]}
                      >
                        <FontSelector
                          style={{
                            width: "100%",
                          }}
                          onSelect={(v) => handleFontChange(v, "fontFamily")}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col
                      span={24}
                      style={{
                        marginRight: 5,
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "flex-start",
                        }}
                      >
                        <div>
                          <Form.Item
                            label="Margin Top"
                            name={["customMarginTopText"]}
                          >
                            <InputNumber
                              style={{
                                width: "100%",
                              }}
                              defaultValue={0}
                              step={1}
                            />
                          </Form.Item>
                          <Form.Item
                            label="Margin Left"
                            name={["customMarginLeftText"]}
                          >
                            <InputNumber
                              defaultValue={0}
                              style={{
                                width: "100%",
                              }}
                              step={1}
                            />
                          </Form.Item>
                        </div>
                        <div
                          style={{
                            marginLeft: 10,
                          }}
                        >
                          <DirectionalPadV2
                            left={customMarginLeftText}
                            top={customMarginTopText}
                            onChange={handleChangeDirectiona}
                          />
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Card>

                <Card
                  className="card-settingt-srabble"
                  title={<strong>Letter points</strong>}
                  bordered={false}
                  style={{ width: "100%" }}
                >
                  <Row>
                    <Col
                      style={{
                        paddingRight: 5,
                      }}
                      span={8}
                    >
                      <Form.Item
                        label="Enabled"
                        valuePropName="checked"
                        name={["letterPointsEnabled"]}
                      >
                        <Switch />
                      </Form.Item>
                    </Col>

                    <Col
                      style={{
                        paddingRight: 5,
                      }}
                      span={8}
                    >
                      <Form.Item
                        label="Font size"
                        name={["letterPointsFontSize"]}
                      >
                        <InputNumber
                          style={{
                            width: "100%",
                          }}
                          min={0}
                          step={1}
                        />
                      </Form.Item>
                    </Col>

                    <Col
                      style={{
                        paddingRight: 5,
                      }}
                      span={8}
                    >
                      <Form.Item
                        className="scrabble_font"
                        label={
                          <div
                            style={{
                              display: "flex",
                            }}
                          >
                            Font Family:{" "}
                            {fontFamilys.letterPointsFontFamily &&
                              fontFamilys.letterPointsFontFamily != "Arial" && (
                                <Popconfirm
                                  placement="topLeft"
                                  title="Are you sure you want to delete?"
                                  onConfirm={() =>
                                    handleDeleteFont("letterPointsFontFamily")
                                  }
                                  okText="Yes"
                                  cancelText="No"
                                >
                                  <Button
                                    style={{
                                      width: "fit-content",
                                      padding: 0,
                                      height: "fit-content",
                                      color: "red",
                                      marginLeft: 5,
                                    }}
                                    type="link"
                                  >
                                    Delete
                                  </Button>
                                </Popconfirm>
                              )}
                          </div>
                        }
                        valuePropName="fontFamily"
                        name={["letterPointsFontFamily"]}
                      >
                        <FontSelector
                          onSelect={(v) =>
                            handleFontChange(v, "letterPointsFontFamily")
                          }
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col
                      span={24}
                      style={{
                        marginRight: 5,
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "flex-start",
                        }}
                      >
                        <div>
                          <Form.Item
                            label="Margin Top"
                            name={["customMarginTopPointText"]}
                          >
                            <InputNumber
                              style={{
                                width: "100%",
                              }}
                              defaultValue={0}
                              step={1}
                            />
                          </Form.Item>
                          <Form.Item
                            label="Margin Left"
                            name={["customMarginLeftPointText"]}
                          >
                            <InputNumber
                              defaultValue={0}
                              style={{
                                width: "100%",
                              }}
                              step={1}
                            />
                          </Form.Item>
                        </div>
                        <div
                          style={{
                            marginLeft: 10,
                          }}
                        >
                          <DirectionalPadV2
                            left={customMarginLeftPointText}
                            top={customMarginTopPointText}
                            onChange={handleChangeDirectionaPoint}
                          />
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Card>
              </Form>
            </div>
            <div 
              className={"body_modal_setting_item2"} 
              style={{
                padding: 5
              }}
            >
              {imageURL && !loading ? (
                
                <img className="image-calendar-preview" width={"100%"} src={imageURL} />
              ) : (
                <>{loading && <Skeleton active />}</>
              )}
            </div>
          </div>
        </Modal>
        <Button
          style={{
            height: "35px",
            width: "100%",
            fontSize: "16px",
            fontWeight: "600",
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
          }}
          type="primary"
          onClick={showModel}
          icon={
             <AiFillSetting
              style={{
                marginRight: 3,
              }}
            />
          }
        >
          Setting
        </Button>
      </div>
    </>
  );
};

export default connect((state) => ({
  workspace: state.artwork.workspace,
}))(ScrabbleSettings);
