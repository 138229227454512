import { Button, message, Tooltip } from "antd";
import React, { useRef } from "react";
import { BsCalendar3 } from "react-icons/bs";
import { connect, useDispatch } from "react-redux";
import { initNewLayer, uniqueID } from "../../../Utilities/helper";
import { ASSET_URL, AWS_API } from "../../../../../config";
import axios from "axios";
import { pick } from "lodash";
import { ARTWORK } from "../../../../../types";
import { handleRender } from "../RenderCanvas/CalendarCanvas";

const upload = async (base64) => {
  var file = await fetch(base64)
    .then((res) => res.arrayBuffer())
    .then((buf) => new File([buf], "tmp.png", { type: "image/png" }));
  var signedUrl = await getSignedUrl(file.type);
  await axios
    .put(signedUrl.url, file, {
      headers: {
        "Content-Type": file.type,
      },
    })
    .catch((err) => console.error(err));
  return {
    id: uniqueID(),
    key: signedUrl.key,
  };
};

const getSignedUrl = async (mime) => {
  var headers = new Headers();
  headers.append("Content-Type", "application/json");
  var requestOptions = {
    method: "GET",
    headers: headers,
    redirect: "follow",
  };
  var signedUrl = null;
  await fetch(`${AWS_API}/signedUrl?type=${mime}`, requestOptions)
    .then((response) => response.json())
    .then((res) => (signedUrl = res))
    .catch((err) => console.error(err));
  return signedUrl;
};

const AddCalendarLayer = (props) => {
  const { layers } = props;
  const stageImage = useRef();
  const dispatch = useDispatch();

  const addImageCalendarPicker = async () => {
    const key = "addLayerCalendar";
    message.loading({
      content: "Retrieving the current date...",
      key,
      duration: 0,
    });
    const today = new Date();
    const day = String(today.getDate()).padStart(2, "0");
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const year = today.getFullYear();
    const date = `${day}/${month}/${year}`;

    setTimeout(() => {
      message.loading({
        content: "Creating image...",
        key,
        duration: 0,
      });
    }, 2000);

    const values = {
      heartUrl: null,
      widthMarker: 100,
      customMarginLeftMarker: 0,
      customMarginTopMarker: 0,
      fontFamilyMonthYear: "",
      fontSizeHeader: 60,
      monthAbbreviation: false,
      weekYearTextAlign: "space-between",
      monthTextTransform: "uppercase",
      fontFamilyWeek: "",
      fontSizeWeek: 48,
      marginTopDaysOfWeek: 40,
      fontFamily: "Arial",
      date,
      fontSize: 48,
      color: "#000000",
      borderColor: "#000000",
      shadowColor: "#000000",
      heightBox: 75,
      width: 800,
      scale: 5,
      borderWidth: 0,
      shadowBlur: 0,
      shadowOffsetX: 0,
      shadowOffsetY: 0,
      opacity: 1,
    };
    const render = await handleRender({...values}, stageImage.current);
    const upfile = await upload(render);
    const newImageLayer = initNewLayer();
    newImageLayer.type = "Image";
    newImageLayer.values[0].file = pick(upfile, ["id", "key"]);
    newImageLayer.imageCalendarPicker = true;
    newImageLayer.personalized = {
      enable: true,
    };
    newImageLayer.title = `Image Calendar Picker  #${
      layers.filter((l) => l.imageCalendarPicker).length + 1
    }`;
    newImageLayer.imageCalendarRender = upfile.key
      ? ASSET_URL + upfile.key
      : null;
    newImageLayer.configsCalendar = {...values};
    dispatch({
      type: ARTWORK.ADD_LAYER,
      payload: newImageLayer,
    });
    message.success({
      content: "Successfully created new Calendar Layer!",
      key,
      duration: 4,
    });
  };
  return (
    <>
      <Tooltip title="Add Image Calendar Picker">
        <div
          style={{
            display: "none",
          }}
          ref={stageImage}
        ></div>

        <Button
          onClick={addImageCalendarPicker}
          style={{
            width: "100%",
            borderRadius: 0,
            borderLeftWidth: 0,
            padding: 0,
          }}
          icon={<BsCalendar3 className="anticon" />}
        ></Button>
      </Tooltip>
    </>
  );
};

export default connect((state) => ({
  workspace: state.artwork.workspace,
}))(AddCalendarLayer);
