import React, { useRef, useEffect } from "react";
import Konva from "konva";
import { CLOUDFRONT_URL } from "../../../../../config";

const calculateStageDimensions = (
  firstDay,
  daysInMonth,
  { margin, fontSizeHeader, marginTopDaysOfWeek, heightBox, width }
) => {
  const widthBox = (width - margin * 2) / 7;
  const totalCells = firstDay + daysInMonth;
  const rows = Math.ceil(totalCells / 7);
  const height =
    margin * 2 +
    fontSizeHeader +
    marginTopDaysOfWeek +
    heightBox +
    rows * heightBox;
  return { widthBox, height };
};

const headerCalendar = ({
  width,
  layer,
  fontSize,
  year,
  month,
  widthBox,
  heightBox,
  margin,
  fontSizeHeader,
  marginTopDaysOfWeek,
  fontFamily,
  borderColor,
  borderWidth,
  shadowColor,
  shadowBlur,
  shadowOffsetX,
  shadowOffsetY,
  opacity,
  color,
  fontSizeWeek,
  fontFamilyMonthYear,
  fontFamilyWeek,
  weekYearTextAlign,
  monthAbbreviation,
  monthTextTransform,
}) => {
  // Tiêu đề tháng và năm
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const monthAbbreviationName = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const widthBoxHeader = width - margin * 2;

  let monthNameLabel = monthAbbreviation
    ? monthAbbreviationName[month - 1]
    : monthNames[month - 1];
  if (monthTextTransform == "uppercase") {
    monthNameLabel = monthNameLabel.toUpperCase();
  }

  if (weekYearTextAlign == "space-between") {
    const monthTextSpaceBetween = new Konva.Text({
      x: margin,
      y: margin,
      text: `${monthNameLabel}`,
      width: widthBoxHeader,
      height: fontSizeHeader,
      fontSize: fontSizeHeader,
      fontFamily: fontFamilyMonthYear,
      fill: color || "#000000",
      align: "left",
      verticalAlign: "middle",
      stroke: borderColor,
      fillAfterStrokeEnabled: true,
      strokeWidth: borderWidth,
      shadowColor,
      shadowBlur,
      shadowOffsetX,
      shadowOffsetY,
      opacity,
    });
    layer.add(monthTextSpaceBetween);

    const yearTextSpaceBetween = new Konva.Text({
      x: margin,
      y: margin,
      text: `${year}`,
      width: widthBoxHeader,
      height: fontSizeHeader,
      fontSize: fontSizeHeader,
      fontFamily: fontFamilyMonthYear,
      fillAfterStrokeEnabled: true,
      fill: color || "#000000",
      align: "right",
      verticalAlign: "middle",
      stroke: borderColor,
      strokeWidth: borderWidth,
      shadowColor,
      shadowBlur,
      shadowOffsetX,
      shadowOffsetY,
      opacity,
    });
    layer.add(yearTextSpaceBetween);
  } else {
    const monthTextYear = new Konva.Text({
      x: margin,
      y: margin,
      text: `${monthNameLabel} ${year}`,
      width: widthBoxHeader,
      height: fontSizeHeader,
      fontSize: fontSizeHeader,
      fontFamily: fontFamilyMonthYear,
      fillAfterStrokeEnabled: true,
      fill: color || "#000000",
      align: weekYearTextAlign,
      verticalAlign: "middle",
      stroke: borderColor,
      strokeWidth: borderWidth,
      shadowColor,
      shadowBlur,
      shadowOffsetX,
      shadowOffsetY,
      opacity,
    });
    layer.add(monthTextYear);
  }

  // Tạo tiêu đề các ngày trong tuần
  const daysOfWeek = ["S", "M", "T", "W", "T", "F", "S"];
  daysOfWeek.forEach((day, index) => {
    const dayText = new Konva.Text({
      x: margin + index * widthBox,
      y: margin + marginTopDaysOfWeek + fontSizeHeader,
      text: day,
      fontSize: fontSizeWeek,
      fontFamily: fontFamilyWeek,
      fill: color || "#000000",
      width: widthBox,
      height: heightBox,
      align: "center",
      verticalAlign: "middle",
      stroke: borderColor,
      strokeWidth: borderWidth,
      fillAfterStrokeEnabled: true,
      shadowColor,
      shadowBlur,
      shadowOffsetX,
      shadowOffsetY,
      opacity,
    });
    layer.add(dayText);
  });
};

const calculateInitialPosition = ({
  margin,
  stageDimensions,
  marginTopDaysOfWeek,
  fontSizeHeader,
  heightBox,
  firstDay,
}) => {
  let x = margin + firstDay * stageDimensions.widthBox;
  let y = margin + marginTopDaysOfWeek + fontSizeHeader + heightBox;
  return [x, y];
};

export const loadImage = (url) => {
  if (url.indexOf("cloudfront.net") >= 0) {
    const splitUrl = url.split("cloudfront.net");
    const key = splitUrl[1];
    url = CLOUDFRONT_URL + "auto" + key;
  }
  return new Promise((resolve, reject) => {
    var imageObj = new Image();
    imageObj.crossOrigin = "anonymous";
    imageObj.onload = function () {
      resolve(imageObj); // Trả về đối tượng hình ảnh khi đã tải xong
    };
    imageObj.onerror = function () {
      reject(new Error("Failed to load image at " + url)); // Trả về lỗi nếu việc tải hình ảnh thất bại
    };
    imageObj.src = url;
  });
};

const drawDays = async (
  layer,
  stageDimensions,
  {
    day,
    heartUrl,
    xStart,
    yStart,
    heightBox,
    fontSize,
    fontFamily,
    color,
    borderColor,
    borderWidth,
    shadowColor,
    shadowBlur,
    shadowOffsetX,
    shadowOffsetY,
    opacity,
    customMarginLeftMarker,
    customMarginTopMarker,
    widthMarker,
    daysInMonth,
    firstDay,
    margin,
  }
) => {
  // Tải hình ảnh trái tim từ URL
  const heartImage = await loadImage(heartUrl);
  const scaleImage = widthMarker / heartImage.width;
  let x = xStart,
    y = yStart;

  for (let d = 1; d <= daysInMonth; d++) {
    // Nếu là ngày nổi bật, vẽ trái tim bao quanh
    if (d === day) {
      const heartIcon = new Konva.Image({
        x:
          x +
          (stageDimensions.widthBox - heartImage.width * scaleImage) / 2 +
          customMarginLeftMarker,
        y:
          y +
          (heightBox - heartImage.height * scaleImage) / 2 +
          customMarginTopMarker,
        image: heartImage,
        width: heartImage.width * scaleImage,
        height: heartImage.height * scaleImage,
      });
      layer.add(heartIcon);
    }

    // Vẽ ngày trong tháng
    const dayText = new Konva.Text({
      x: x,
      y: y,
      text: d.toString(),
      fontSize: fontSize,
      fontFamily: fontFamily,
      fill: color || "#000000",
      width: stageDimensions.widthBox,
      align: "center",
      verticalAlign: "middle",
      fillAfterStrokeEnabled: true,
      height: heightBox,
      stroke: borderColor,
      strokeWidth: borderWidth,
      shadowColor,
      shadowBlur,
      shadowOffsetX,
      shadowOffsetY,
      opacity,
    });
    layer.add(dayText);

    // Di chuyển vị trí cho ngày tiếp theo
    x += stageDimensions.widthBox;
    if ((firstDay + d) % 7 === 0) {
      // Khi đủ 7 ngày, chuyển xuống hàng mới
      x = margin;
      y += heightBox;
    }
  }
};

export const handleRender = async (values, canvasCurrent) => {
  const { scale } = values;
  [
    "fontSizeHeader",
    "fontSize",
    "fontSizeWeek",
    "margin",
    "marginTopDaysOfWeek",
    "heightBox",
    "width",
    "widthMarker",
    "borderWidth",
    "shadowBlur",
    "shadowOffsetX",
    "shadowOffsetY",
    "customMarginLeftMarker",
    "customMarginTopMarker",
  ].forEach((key) => {
    if (values[key]) {
      values[key] = values[key] * scale;
    }
  });

  var {
    date,
    fontFamily,
    fontFamilyMonthYear,
    fontFamilyWeek,
    fontSizeHeader,
    fontSize,
    fontSizeWeek,
    margin = 20 * scale,
    marginTopDaysOfWeek,
    heightBox,
    width,
    widthMarker,
    color,
    borderColor,
    borderWidth,
    shadowColor,
    shadowBlur,
    shadowOffsetX,
    shadowOffsetY,
    opacity,
    customMarginLeftMarker,
    customMarginTopMarker,
    weekYearTextAlign,
    monthAbbreviation,
    monthTextTransform,
    heartUrl,
  } = values;

  heartUrl =
    heartUrl ||
    "https://m106v206c3.execute-api.ap-southeast-2.amazonaws.com//auto/6b20c720-a71c-11ef-a3e4-4e5b5c96fb45__ove.png";
  const [day, month, year] = date.split("/").map(Number);

  // Tính toán ngày đầu tiên và số ngày trong tháng
  const firstDay = new Date(year, month - 1, 1).getDay();
  const daysInMonth = new Date(year, month, 0).getDate();

  // configs
  const stageDimensions = calculateStageDimensions(firstDay, daysInMonth, {
    margin,
    fontSizeHeader,
    marginTopDaysOfWeek,
    heightBox,
    width,
  });

  const stage = new Konva.Stage({
    width,
    height: stageDimensions.height,
    container: canvasCurrent,
  });

  const layer = new Konva.Layer();
  stage.add(layer);

  // Vẽ tiêu đề tháng và năm.
  headerCalendar({
    fontSize,
    layer,
    width,
    month,
    year,
    widthBox: stageDimensions.widthBox,
    heightBox,
    margin,
    fontSizeHeader,
    marginTopDaysOfWeek,
    fontFamily,
    borderColor,
    borderWidth,
    shadowColor,
    shadowBlur,
    shadowOffsetX,
    shadowOffsetY,
    opacity,
    color,
    fontSizeWeek,
    fontFamilyMonthYear:
      fontFamilyMonthYear && fontFamilyMonthYear != "Arial"
        ? fontFamilyMonthYear
        : fontFamily,
    fontFamilyWeek:
      fontFamilyWeek && fontFamilyWeek != "Arial" ? fontFamilyWeek : fontFamily,
    weekYearTextAlign,
    monthAbbreviation,
    monthTextTransform,
  });

  const [xStart, yStart] = calculateInitialPosition({
    margin,
    stageDimensions,
    firstDay,
    fontSizeHeader,
    heightBox,
    marginTopDaysOfWeek,
  });

  // Vẽ các ngày trong tháng
  await drawDays(layer, stageDimensions, {
    xStart,
    yStart,
    day,
    heartUrl,
    borderColor,
    borderWidth,
    color,
    customMarginLeftMarker,
    customMarginTopMarker,
    daysInMonth,
    firstDay,
    fontFamily,
    fontSize,
    heightBox,
    margin,
    opacity,
    shadowBlur,
    shadowColor,
    shadowOffsetX,
    shadowOffsetY,
    widthMarker,
  });

  layer.draw();
  const canvas = stage.toCanvas();

  const base64 = canvas.toDataURL();
  return base64;
};

const CalendarCanvas = ({ configs, onChange, uuid }) => {
  const canvasRef = useRef(null);

  const handeCreateImage = async (value) => {
    const render = await handleRender(value, canvasRef.current);
    onChange(render);
  };

  useEffect(() => {
    if (uuid) {
      handeCreateImage(configs);
    }
  }, [uuid]);
  return (
    <>
      <div
        style={{
          position: "fixed",
          left: 0,
          right: 0,
          top: 0,
          bottom: 0,
          width: 1000,
          height: 1000,
          background: "#fff",
          display: "none",
        }}
      >
        <canvas
          ref={canvasRef}
          width={1000}
          height={1000}
          style={{ border: "1px solid black" }}
        ></canvas>
      </div>
    </>
  );
};

export default CalendarCanvas;
