import React from "react";
import { FaArrowDown, FaArrowLeft, FaArrowRight, FaArrowUp } from "react-icons/fa";

const DirectionalPadV2 = ({left, top, onChange}) => {
    const handelChangeUp = () => {
      onChange({key: 'top', value: top - 1});
    }
    const handelChangeRight = () => {
      onChange({key: 'left', value: left + 1});
    }
    const handelChangeLeft = () => {
      onChange({key: 'left', value: left - 1});
    }
    const handelChangeDown = () => {
      onChange({key: 'top', value: top + 1});
    }
    return <>
       <div className="dpad">
        <div className="dpad-up">
          <button onClick={handelChangeUp} className="btn-directional-pad">
            <FaArrowUp />
          </button>
        </div>

        <div className="dpad-right">
          <button onClick={handelChangeRight}  className="btn-directional-pad">
            <FaArrowRight />
          </button>
        </div>

        <div className="dpad-left">
          <button onClick={handelChangeLeft}  className="btn-directional-pad">
            <FaArrowLeft />
          </button>
        </div>

        <div className="dpad-down">
          <button onClick={handelChangeDown}  className="btn-directional-pad">
            <FaArrowDown />
          </button>
        </div>
      </div>
    </>
}
export default DirectionalPadV2;